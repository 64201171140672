<template>
  <div class="pass-history-view v3-pass-history">
    <ActionPanel class="mb-3" show-top-left-slot>
      <template #left-content>
        <div class="mt-3">
          <div class="d-flex flex-column mb-3">
            <div class="d-flex mb-3">
              <CustomSelect
                v-if="gradeYears"
                v-model="state.filterParams.grade_years"
                class="pt-selector ms-1 me-2"
                :options="gradeYears"
                placeholder="Select year"
                :close-on-select="true"
                @update:model-value="setCalendarGradeYears"
              ></CustomSelect>
              <CustomSelectDatePicker
                class="pt-selector ms-1"
                v-model="state.filterParams.dates"
                :date-ranges="optionalDateRanges"
                @update:model-value="selectRange"
              ></CustomSelectDatePicker>
            </div>
          </div>
        </div>
      </template>
      <template #bottom-content>
        <div class="justify-content-end text-end">
          <CAlert v-if="state.errors && !isLoading" color="danger">
            {{ state.errors }}
          </CAlert>
          <InfoBox
            v-if="showReloadButtonAndProgressMessage"
            :message="progressMessage"
            class="mt-2"
          />
          <BaseButton
            v-if="showReloadButtonAndProgressMessage"
            class="ms-2"
            rounded
            @click="handleReload()"
          >
            Reload
          </BaseButton>
          <BaseButton class="ms-2" solid rounded @click="submit()"
            >Submit</BaseButton
          >
          <BaseButton class="ms-2" rounded @click="clearFilter()"
            >Clear all</BaseButton
          >
        </div>
      </template>
    </ActionPanel>
    <PerPage
      v-if="state.pagination && state.pagination.total > 0"
      :pagination="state.pagination"
      :showing-entries="true"
      @on-page-change="setPerPage"
    />

    <div v-if="state.isLoading" class="spinner-container">
      <CSpinner color="primary" />
    </div>
    <DataTable
      v-else
      class="datatable bg-white text-center mt-3"
      add-table-classes="totalPassReportsDatatable"
      :fields="dataTableFields"
      :items="passReports"
      :no-items-view="{
        noResults: 'No items available',
        noItems: 'No items available'
      }"
    >
      <template #start_date="{ item }">
        <td>{{ item?.csv_data?.start_date }}</td>
      </template>
      <template #end_date="{ item }">
        <td>{{ item?.csv_data?.end_date }}</td>
      </template>
      <template #total_passes="{ item }">
        <td>
          {{ item?.csv_data?.total_passes || 0 }}
        </td>
      </template>
      <template #total_stu_passes="{ item }">
        <td>
          {{ item?.csv_data?.stu_passes || 0 }}
        </td>
      </template>
      <template #total_tch_passes="{ item }">
        <td>
          {{ item?.csv_data?.tch_passes || 0 }}
        </td>
      </template>
      <template #total_apt_passes="{ item }">
        <td>
          {{ item?.csv_data?.apt_passes || 0 }}
        </td>
      </template>
      <template #total_ksk_passes="{ item }">
        <td>
          {{ item?.csv_data?.ksk_passes || 0 }}
        </td>
      </template>
      <template #requested_date="{ item }">
        <td>
          {{
            item?.request_date
              ? $helpers.transformUTC(item.request_date, "MM/DD/YYYY h:mm A")
              : "-"
          }}
        </td>
      </template>
      <template #last_rerun_date="{ item }">
        <td>
          {{
            item?.last_rerun_date
              ? $helpers.transformUTC(item.last_rerun_date, "MM/DD/YYYY h:mm A")
              : "-"
          }}
        </td>
      </template>
      <template #action="{ item }">
        <td>
          <div v-if="state.isReportReRun[item?.id]" class="spinner-container">
            <CSpinner color="primary" />
          </div>
          <div v-else class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <span class="me-2">{{ item?.status || "In Progress" }}</span>
              <i
                v-show="state.reportIds?.includes(item?.id)"
                :title="progressToolTip"
                class="ri-information-line"
              />
            </div>
            <div class="d-flex align-items-center">
              <BaseButton
                class="ms-2 d-flex justify-content-center align-items-center"
                solid
                rounded
                @click="reRun(item)"
                :disabled="
                  !state.reportIds?.includes(item?.id) ||
                  state.reRunStatus.reRunButtonText[item?.id] === 'In Progress'
                "
                style="width: 120px"
                >{{
                  state.reRunStatus.reRunButtonText[item?.id] === "In Progress"
                    ? "In Progress"
                    : "Re-run"
                }}
              </BaseButton>
              <div @click="refresh(item)">
                <IconButton
                  class="static"
                  :icon="`ri-refresh-line`"
                  :style="{
                    visibility:
                      state.reRunStatus.reRunButtonText[item?.id] ===
                        'In Progress' || showRefresh(item)
                        ? 'visible'
                        : 'hidden'
                  }"
                ></IconButton>
              </div>
              <div @click="item?.status === 'Done' ? downloadCsv(item) : null">
                <IconButton
                  class="static"
                  :icon="`ri-download-2-line`"
                  :class="{
                    'blurred-icon':
                      state.reRunStatus.reRunButtonText[item?.id] ===
                        'In Progress' || showRefresh(item)
                  }"
                ></IconButton>
              </div>
            </div>
          </div>
        </td>
      </template>
    </DataTable>

    <Pagination
      v-if="state.pagination.pages > 1 && !state.isLazyLoadingMode"
      :active-page="state.pagination.activePage"
      :pages="state.pagination.pages"
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      size="lg"
      align="center"
      @update:active-page="setActivePage"
    ></Pagination>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from "vue"
import { useStore } from "vuex"
import moment from "moment-timezone"
import ActionPanel from "../shared/DataTable/ActionPanel.vue"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import CustomSelectDatePicker from "@/v3components/shared/Form/CustomSelectDatePicker.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import IconButton from "@/v3components/shared/Buttons/IconButton.vue"
import helpersJS from "@/helpers/index"
import PerPage from "@/v3components/shared/DataTable/Perpage.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"

export default {
  name: "PassTotalsTable",
  components: {
    ActionPanel,
    CustomSelect,
    CustomSelectDatePicker,
    BaseButton,
    DataTable,
    IconButton,
    PerPage,
    InfoBox
  },
  setup() {
    const store = useStore()
    const state = reactive({
      isLazyLoadingMode: false,
      isLoading: false,
      isReportReRun: {},
      reportStatus: {
        latestReportStatus: null,
        latestReportFilters: null
      },
      reportIds: [],
      reRunStatus: {
        reRunButtonText: {},
        reportStatus: {},
        lastReRunDate: {}
      },
      filterParamsDateRange: null,
      filterParams: {
        grade_years: [],
        dates:
          moment().format("MM/DD/YYYY") + " - " + moment().format("MM/DD/YYYY")
      },
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      errors: ""
    })

    const gradeYears = computed(() => {
      const gradeYears = []
      const currentYear = new Date().getFullYear()
      const today = moment().format("YYYY-MM-DD HH:mm:ss")
      const newSchoolYear = moment(today).isAfter(
        moment(`${currentYear}-06-30 23:59:59`)
      )
      if (moment(today).year() == currentYear && newSchoolYear) {
        for (let i = 0; i <= currentYear - 2021; i++) {
          gradeYears.push({
            value: [2021 + i, 2022 + i],
            label: `${2021 + i}-${22 + i}`
          })
        }
      } else {
        for (let i = 0; i < currentYear - 2021; i++) {
          gradeYears.push({
            value: [2021 + i, 2022 + i],
            label: `${2021 + i}-${22 + i}`
          })
        }
      }
      return gradeYears
    })

    const maxDate = computed(() => {
      if (state.filterParams.grade_years?.value) {
        return new Date(state.filterParams.grade_years.value[1], 5, 30)
      }
      return new Date()
    })

    const minDate = computed(() => {
      if (state.filterParams.grade_years?.value) {
        return new Date(state.filterParams.grade_years.value[0], 6, 1)
      }
      return new Date()
    })

    const progressToolTip = () => {
      return `Number of Passes have increased`
    }

    const optionalDateRanges = computed(() => {
      if (state.filterParams.grade_years?.value && minDate.value) {
        return [
          {
            label: "Today",
            id: "",
            range: helpersJS.stringDateRange(moment(), moment())
          },
          {
            label: "Yesterday",
            id: "",
            range: helpersJS.stringDateRange(
              moment().subtract(1, "days"),
              moment().subtract(1, "days")
            )
          },
          {
            label: "Last 7 days",
            id: "",
            range: helpersJS.stringDateRange(
              moment().subtract(7, "days") < moment(minDate.value)
                ? moment(minDate.value)
                : moment().subtract(7, "days"),
              moment()
            )
          },
          {
            label: "Last 14 days",
            id: "",
            range: helpersJS.stringDateRange(
              moment().subtract(14, "days") < moment(minDate.value)
                ? moment(minDate.value)
                : moment().subtract(14, "days"),
              moment()
            )
          },
          {
            label: "Last 30 days",
            id: "",
            range: helpersJS.stringDateRange(
              moment().subtract(30, "days") < moment(minDate.value)
                ? moment(minDate.value)
                : moment().subtract(30, "days"),
              moment()
            )
          },
          {
            label: "This month",
            id: "",
            range: helpersJS.stringDateRange(
              moment().startOf("month"),
              moment().endOf("month")
            )
          },
          {
            label: "This year",
            id: "",
            range:
              helpersJS.currTzDate(minDate.value) +
              " - " +
              helpersJS.currTzDate(maxDate.value)
          },
          {
            label: "Custom range",
            range: null,
            id: "custom"
          }
        ]
      }
      return [
        {
          label: "Today",
          id: "",
          range: helpersJS.stringDateRange(moment(), moment())
        }
      ]
    })

    const setCalendarGradeYears = () => {
      state.filterParams.dates = {
        label:
          helpersJS.currTzDate(minDate.value) +
          " - " +
          helpersJS.currTzDate(maxDate.value),
        id: "custom",
        range:
          helpersJS.currTzDate(minDate.value) +
          " - " +
          helpersJS.currTzDate(maxDate.value),
        current_school_year:
          helpersJS.currTzDate(helpersJS.minSchoolYearDate()) +
          " - " +
          helpersJS.currTzDate(helpersJS.maxSchoolYearDate()),
        startDate: helpersJS.currTzDate(minDate.value),
        endDate: helpersJS.currTzDate(maxDate.value)
      }
      selectRange(
        helpersJS.currTzDate(minDate.value) +
          " - " +
          helpersJS.currTzDate(maxDate.value)
      )
    }

    const selectRange = (range) => {
      state.filterParamsDateRange = {
        start: range.substring(0, range.indexOf(" -")),
        end: range.substring(range.indexOf("- ") + 2)
      }
    }

    const clearFilter = () => {
      clearFilterParams()
    }

    const clearFilterParams = () => {
      state.filterParams = Object.assign(state.filterParams, {
        grade_years: gradeYears.value
          ? gradeYears.value[gradeYears.value.length - 1]
          : state.filterParams.grade_years,
        dates:
          moment().format("MM/DD/YYYY") + " - " + moment().format("MM/DD/YYYY")
      })
    }
    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        handleReports()
      }
    }

    //DATATABLE
    const dataTableFields = [
      {
        key: "start_date",
        label: "Start Date",
        type: "tabular",
        _style: "min-width:125px"
      },
      {
        key: "end_date",
        label: "End Date",
        type: "tabular",
        _style: "min-width:125px"
      },
      {
        key: "total_passes",
        label: "Total Passes",
        type: "tabular"
      },
      {
        key: "total_stu_passes",
        label: "STU Passes",
        type: "tabular"
      },
      {
        key: "total_tch_passes",
        label: "TCH Passes",
        type: "tabular"
      },
      {
        key: "total_apt_passes",
        label: "APT Passes",
        type: "tabular"
      },
      {
        key: "total_ksk_passes",
        label: "KSK Passes",
        type: "tabular"
      },
      {
        key: "requested_date",
        label: "Requested Date",
        type: "tabular"
      },
      {
        key: "last_rerun_date",
        label: "Last Re-run Date",
        type: "tabular"
      },
      {
        key: "action",
        label: "Action",
        type: "tabular"
      }
    ]

    const passReportRequestData = computed(() => {
      return {
        grad_year: state.filterParams?.grade_years?.value?.[0],
        date_range: {
          start: state.filterParamsDateRange?.start,
          end: state.filterParamsDateRange?.end
        }
      }
    })

    const showRefresh = (report) => {
      return (
        state.reRunStatus.reportStatus[report?.id] === "In Progress" ||
        state.reRunStatus.lastReRunDate[report?.id]
      )
    }

    const showReloadButtonAndProgressMessage = computed(() => {
      return (
        state.reportStatus.latestReportStatus === "Pending" ||
        state.reportStatus.latestReportStatus === "In Progress"
      )
    })

    const progressMessage = computed(() => {
      const filters = state.reportStatus.latestReportFilters
      if (!filters.grad_year || !filters.date_range) {
        return ""
      }

      return `Report with filters: Graduation Year: ${filters.grad_year}, Start Date: ${filters.date_range.start}, End Date: ${filters.date_range.end} is In Progress. Click 'Reload' to see its status.`
    })

    const submit = () => {
      store
        .dispatch(
          "totalPassReports/submitTotalPassReportRequest",
          passReportRequestData.value
        )
        .then((response) => {
          if (response?.data?.error) {
            state.errors = response?.data?.error
            setTimeout(() => {
              state.errors = ""
            }, 2000)
          } else {
            showLatestReportStatus()
          }
        })
        .catch((err) => {
          state.errors = err
          setTimeout(() => {
            state.errors = ""
          }, 3000)
        })
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      handleReports(state.pagination.activePage)
      helpersJS.scrollToTop()
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            passReports.value &&
            passReports.value.length &&
            state.pagination.total > passReports.value.length
          ) {
            if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
              state.pagination.activePage = state.pagination.activePage + 1
              handleReports(state.pagination.activePage, true, false)
            }
          }
        }
      }
    }

    const handleReports = (page, isLazyLoadingMode, noLoader = true) => {
      state.isLoading = !noLoader
      state.pagination.activePage = page ? page : 1
      getPassReports(page, isLazyLoadingMode)
    }

    const downloadCsv = (item) => {
      // Assume you have a reportId property in the item object
      const reportId = item?.id
      // Make an API call to download the file
      store
        .dispatch("totalPassReports/exportTotalPassReportCsv", reportId)
        .then((response) => {
          const fileContents = response?.data?.url?.original?.fileContents
          const fileName = response?.data?.url?.original?.fileName
          const blob = new Blob([fileContents], {
            type: "text/csv;charset=utf-8;"
          })

          const link = document.createElement("a")
          const url = URL.createObjectURL(blob)
          link.href = url
          link.setAttribute("download", fileName)

          // Append link to the body and trigger the download
          document.body.appendChild(link)
          link.click()

          // Cleanup
          document.body.removeChild(link)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          reject(err)
        })
    }

    const handleReload = () => {
      getPassReports()
      showLatestReportStatus()
    }

    //GET THE REPORTS FROM BE
    const getPassReports = (page = 1, isLazyLoadingMode) => {
      state.isLoading = true
      store
        .dispatch("totalPassReports/getTotalPassReports", {
          page,
          per_page: state.pagination.per_page.value
        })
        .then((response) => {
          const data = response?.data
          if (isLazyLoadingMode) {
            store.commit("totalPassReports/PUSH_PASS_REPORTS", data)
          } else {
            store.commit("totalPassReports/SET_PASS_REPORTS", data)
          }

          if (data.meta) {
            state.pagination.total = data.meta.total
            state.pagination.from = data.meta.from
            state.pagination.to = data.meta.to
            state.pagination.pages = Math.ceil(
              data.meta.total / data.meta.per_page
            )
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
          state.isLoading = false
        })
        .catch((err) => {
          state.isLoading = false
          state.errors = err?.message
        })
    }

    const passReports = computed(() => {
      return store.getters["totalPassReports/passReports"]
    })

    const showLatestReportStatus = () => {
      store
        .dispatch("totalPassReports/getLatestReportStatus")
        .then((response) => {
          const data = response?.data?.data
          state.reportStatus.latestReportStatus = data?.status
          state.reportStatus.latestReportFilters = JSON.parse(data?.filters)
        })
        .catch((err) => {
          state.errors = err?.message
        })
    }

    const reportIdsWithLatestCount = async () => {
      try {
        const response = await store.dispatch(
          "totalPassReports/getReportIdsWithDifferentCount"
        )
        const data = response?.data?.data
        state.reportIds = data
        return data
      } catch (err) {
        state.errors = err
      }
    }

    const reRun = (report) => {
      state.reRunStatus.reRunButtonText[report?.id] = "In Progress"
      store.dispatch("totalPassReports/updateReportWithLatestCount", report?.id)
    }

    const refresh = async (report) => {
      state.isReportReRun[report?.id] = true
      try {
        const response = await store.dispatch(
          "totalPassReports/fetchPassReport",
          report?.id
        )
        state.reRunStatus.reportStatus[report?.id] =
          response?.data?.data?.status
        state.reRunStatus.lastReRunDate[report?.id] =
          report?.last_rerun_date === response?.data?.data?.last_rerun_date

        const updatedItem = response?.data?.data
        store.commit("totalPassReports/UPDATE_REPORT", updatedItem)

        const resp = await reportIdsWithLatestCount()
        if (resp) {
          state.reRunStatus.reRunButtonText[report?.id] = "Re-run"
          state.isReportReRun[report?.id] = false
        }
      } catch (err) {
        state.errors = err
      }
    }

    onMounted(() => {
      store.commit("totalPassReports/SET_IS_REPORT_NEW", false)
      selectRange(state.filterParams.dates)
      initLazyLoading()
      handleReports()
      showLatestReportStatus()
      reportIdsWithLatestCount()
      state.filterParams.grade_years = gradeYears.value
        ? gradeYears.value[gradeYears.value.length - 1]
        : state.filterParams.grade_years
    })

    return {
      state,
      gradeYears,
      clearFilter,
      optionalDateRanges,
      selectRange,
      setCalendarGradeYears,
      dataTableFields,
      passReports,
      getPassReports,
      submit,
      downloadCsv,
      setActivePage,
      setPerPage,
      handleReload,
      showReloadButtonAndProgressMessage,
      progressMessage,
      reportIdsWithLatestCount,
      reRun,
      progressToolTip,
      refresh,
      showRefresh
    }
  }
}
</script>

<style scoped>
.blurred-icon {
  opacity: 0.25;
  cursor: not-allowed;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
