import { getFingerprint } from "@thumbmarkjs/thumbmarkjs"
import store from "@/store/index"
import visitorConstants from "@/constants/visitorConstants"
import xmlConstants from "@/constants/xmlConstants"

export const saveDeviceFingerprint = async () => {
  try {
    const fingerprint = await getFingerprint()
    localStorage.setItem(
      visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_FINGERPRINT,
      fingerprint
    )
  } catch (error) {
    // Error handling is kept purposefully
    // eslint-disable-next-line no-console
    console.log("Error in saving device fingerprint: ", error)
  }
}

export const getAllDymoPrinters = () => {
  const printers = window?.dymo?.label?.framework?.getPrinters() || []
  return printers
}

const areEqual = (a, b, checkExactMatch = true) => {
  return checkExactMatch
    ? a?.toString()?.toLowerCase()?.trim() ===
        b?.toString()?.toLowerCase()?.trim()
    : a
        ?.toString()
        ?.toLowerCase()
        ?.trim()
        ?.includes(b?.toString()?.toLowerCase()?.trim())
}

export const getConnectedPrinterDetailsByName = (
  name,
  checkExactMatch = true
) => {
  const printers = getAllDymoPrinters() || []
  if (!printers.length) {
    return null
  }
  const matchingPrinter =
    printers.find(
      (printer) =>
        areEqual(printer?.name, name, checkExactMatch) &&
        areEqual(printer?.printerType, "LabelWriterPrinter")
    ) || null
  return matchingPrinter || null
}

export const isPrinterConnected = (name, checkExactMatch = true) => {
  return (
    getConnectedPrinterDetailsByName(name, checkExactMatch)?.isConnected ||
    false
  )
}

export const generateMarkupAndStartPrint = async ({
  firstName,
  lastName,
  date,
  reasonForVisit,
  imageURL,
  schoolName,
  destination
}) => {
  reasonForVisit = reasonForVisit
    ?.toString()
    ?.slice(0, 30)
    ?.trim()
    ?.toUpperCase()
  destination = destination?.toString()?.slice(0, 30)?.trim()?.toUpperCase()

  const printerBrand = localStorage.getItem(
    visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_BRAND
  )
  const printerName = localStorage.getItem(
    visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_NAME
  )

  if (
    printerBrand
      ?.toString()
      ?.trim()
      ?.toLowerCase()
      ?.includes(visitorConstants.PRINTER_CONFIG.DYMO.BRAND)
  ) {
    return await startPrintJob({
      firstName,
      lastName,
      date,
      reasonForVisit,
      imageURL,
      printerName,
      destination
    })
  } else if (
    printerBrand
      ?.toString()
      ?.trim()
      ?.toLowerCase()
      ?.includes(visitorConstants.PRINTER_CONFIG.BROTHER.BRAND)
  ) {
    return {
      success: false,
      message: visitorConstants.PRINTER_MESSAGES.BROTHER_PRINTER_NOT_ALLOWED
    }
  } else {
    const stopAutoDetect = localStorage.getItem(
      visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.STOP_AUTO_DETECT
    )
    const currentUser = store?.getters["authentication/user"] || {
      created_at: new Date()
    }
    // Fix for older configured dymo printer users (autodetect and set printer configuration)
    try {
      if (
        new Date("2022-05-04") > new Date(currentUser?.created_at) &&
        stopAutoDetect != "true"
      ) {
        const { success, message } = await detectDymoPrinters()
        if (!success) {
          return { success, message }
        }
        return await generateMarkupAndStartPrint({
          firstName,
          lastName,
          date,
          reasonForVisit,
          imageURL,
          schoolName,
          destination
        })
      } else {
        return {
          success: false,
          message: visitorConstants.PRINTER_MESSAGES.NO_PRINTER_CONFIGURED
        }
      }
    } catch (error) {
      return {
        success: false,
        message:
          error?.message ||
          visitorConstants.PRINTER_MESSAGES.ERROR_IN_DETECT_DYMO_PRINTER
      }
    }
  }
}

// Function for detecting dymo printer automatically for backward compatibility
async function detectDymoPrinters() {
  const printers = getAllDymoPrinters() || []
  if (!printers.length) {
    return {
      success: false,
      message: visitorConstants.PRINTER_MESSAGES.NO_DYMO_PRINTERS_FOUND
    }
  }

  const isDymoPrinterConnected =
    isPrinterConnected(visitorConstants.PRINTER_CONFIG.DYMO.NAME, false) ||
    false
  if (!isDymoPrinterConnected) {
    return {
      success: false,
      message: visitorConstants.PRINTER_MESSAGES.DYMO_PRINTER_NOT_CONNECTED
    }
  }

  const printerName =
    getConnectedPrinterDetailsByName(
      visitorConstants.PRINTER_CONFIG.DYMO.NAME,
      false
    )?.name || ""

  if (printerName) {
    localStorage.setItem(
      visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_BRAND,
      visitorConstants.PRINTER_CONFIG.DYMO.BRAND
    )
    localStorage.setItem(
      visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_NAME,
      printerName
    )
  }

  localStorage.setItem(
    visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.STOP_AUTO_DETECT,
    "true"
  )

  return {
    success: true,
    message: "DYMO printers detected and set successfully."
  }
}

async function startPrintJob({
  firstName,
  lastName,
  date,
  reasonForVisit,
  imageURL,
  printerName,
  destination
}) {
  const printers = getAllDymoPrinters() || []
  if (!printers.length) {
    return {
      success: false,
      message: visitorConstants.PRINTER_MESSAGES.NO_DYMO_PRINTERS_FOUND
    }
  }

  const isDymoPrinterConnected = isPrinterConnected(printerName) || false
  if (!isDymoPrinterConnected) {
    return {
      success: false,
      message: visitorConstants.PRINTER_MESSAGES.DYMO_PRINTER_NOT_CONNECTED
    }
  }

  const matchingPrinter = getConnectedPrinterDetailsByName(printerName)

  let imageXml = ""
  try {
    let image = window?.dymo.label.framework.loadImageAsPngBase64(imageURL)

    if (atob(image) == "url parameter is required") {
      imageURL = imageURL.replace("%3D", "=")
      imageURL = imageURL.replace("%253D", "=")
      image = window?.dymo.label.framework.loadImageAsPngBase64(imageURL)
    }
    imageXml = xmlConstants.useImageXMLTemplate(image)
  } catch (error) {
    imageXml = ""
  }

  const name = firstName.toUpperCase() + " " + lastName.toUpperCase()
  const isReasonToBePrinted = destination?.toString()?.trim()?.length === 0
  const descriptionLabel = isReasonToBePrinted
    ? "REASON FOR VISIT"
    : "DESTINATION"
  const descriptionValue = isReasonToBePrinted
    ? reasonForVisit.toUpperCase()
    : destination.toUpperCase()

  const labelXml = xmlConstants.useLabelXMLTemplate(
    imageXml,
    name,
    date,
    descriptionLabel,
    descriptionValue
  )

  const label = window?.dymo.label.framework.openLabelXml(labelXml)
  label.print(matchingPrinter?.name)

  return { success: true, message: "Printing started successfully." }
}
