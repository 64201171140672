<template>
  <div class="v3-form detentions-form">
    <div class="mb-3">
      <Label required>Detention name</Label>
      <InputField
        :invalid-feedback="errors.name || state.serverErrors.name"
        class="w-full"
        v-model="state.form.name"
      ></InputField>
    </div>
    <div class="mb-3">
      <Label required>Delay before booking</Label>
      <InputField
        append-text="Days"
        :invalid-feedback="
          errors.days_before_booking || state.serverErrors.days_before_booking
        "
        class="w-full"
        v-model="state.form.days_before_booking"
        input-type="number"
        :min-number="`1`"
        :max-number="`30`"
      ></InputField>
    </div>
    <div class="mb-3">
      <Label required>Description</Label>
      <InputField
        :invalid-feedback="errors.description || state.serverErrors.description"
        :rows="4"
        class="w-full"
        type="textarea"
        v-model="state.form.description"
      ></InputField>
    </div>
    <div class="mb-3">
      <Label required>Color</Label>
      <ColorPicker
        :invalid-feedback="errors.color || state.serverErrors.color"
        class="w-full"
        v-model="state.form.color"
      ></ColorPicker>
    </div>
    <div class="row mb-3">
      <div class="col-md-6">
        <Label required>Start time</Label>
        <CustomTimePicker
          format="hh:mm a"
          :invalid-feedback="
            errors.startTime || state.serverErrors.default_start_time
          "
          v-model="state.form.startTime"
        ></CustomTimePicker>
      </div>
      <div class="col-md-6">
        <Label required>Length</Label>
        <InputField
          append-text="Min"
          :invalid-feedback="
            errors.detentionLength || state.serverErrors.number_of_minutes
          "
          class="w-full"
          v-model="state.form.detentionLength"
          input-type="number"
          :min-number="`1`"
        ></InputField>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <Label required>Room</Label>
        <LazyLoadSelect
          :selected="state.form.room"
          placeholder="Select one"
          type="locations"
          @update:model-value="
            (value) => {
              state.form.room = value
            }
          "
          :invalid-feedback="errors.room || state.serverErrors.room_id"
        ></LazyLoadSelect>
      </div>
      <div class="col-md-6">
        <Label required>Max capacity</Label>
        <InputField
          class="w-full"
          v-model="state.form.maxCapacity"
          :invalid-feedback="errors.maxCapacity || state.serverErrors.max_cap"
        ></InputField>
      </div>
      <div class="mt-3 mb-3">
        <Label>Grade years</Label>
        <CustomSelect
          :close-on-select="false"
          :searchable="false"
          placeholder="Select grade years"
          multiple
          v-model="state.form.gradeYears"
          :options="schoolGradYears ? schoolGradYears : []"
        ></CustomSelect>
      </div>
      <div>
        <InfoBox
          v-if="state.serverRes"
          class="mt-4"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
      </div>
      <div class="text-center">
        <LoadingButton class="me-2 px-4" @click="onCancel" rounded
          >Cancel</LoadingButton
        >
        <LoadingButton
          :is-loading="state.isProcessing"
          @click="submit(isUpdateMode)"
          class="mt-4 px-4"
          solid
          rounded
          >{{ isUpdateMode ? "Update" : "Submit" }}</LoadingButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "@/v3components/shared/Form/InputField.vue"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import ColorPicker from "@/v3components/shared/Form/ColorPicker.vue"
import Label from "@/v3components/shared/Form/Label.vue"
import CustomTimePicker from "@/v3components/shared/Form/CustomTimePicker.vue"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"
import { useStore } from "vuex"
import { reactive, computed, onMounted } from "vue"
import moment from "moment-timezone"

export default {
  name: "CreateTardyDetentionForm",
  components: {
    InputField,
    ColorPicker,
    Label,
    CustomTimePicker,
    LazyLoadSelect,
    LoadingButton,
    InfoBox,
    CustomSelect
  },
  props: {
    editableDetention: {
      type: Object,
      default: null
    }
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      isProcessing: false,
      serverErrors: {},
      serverRes: null,
      form: {
        name: "",
        days_before_booking: 1,
        description: "",
        color: "",
        startTime: "",
        detentionLength: "",
        room: null,
        maxCapacity: "-",
        gradeYears: null
      }
    })

    const schoolGradYears = computed(
      () => store.getters["passBlocks/gradeYears"]
    )

    onMounted(() => {
      store.dispatch("passBlocks/getGradeYears")
      if (props.editableDetention) {
        setFormData({
          name: props.editableDetention.name,
          days_before_booking: props.editableDetention.days_before_booking,
          description: props.editableDetention.description,
          startTime: moment(
            props.editableDetention.default_start_time,
            "hh:mm:ss"
          ).format("hh:mm a"),
          detentionLength: props.editableDetention.number_of_minutes,
          room: {
            label: props.editableDetention.room.name,
            value: {
              id: props.editableDetention.room.id,
              type: "App\\Models\\Room"
            }
          },
          maxCapacity:
            props.editableDetention.max_cap == null
              ? "-"
              : props.editableDetention.max_cap,
          color: props.editableDetention.color,
          gradeYears: props.editableDetention.grad_years
        })
      }
    })

    const validationMessages = {
      required: "This field is required",
      numberRange: "This field should be a number between 1-30",
      minNumber: "This field should be a minimum 1"
    }
    const validations = {
      form: {
        name: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        days_before_booking: {
          numberRange: helpers.withMessage(
            validationMessages.numberRange,
            (value) => {
              return state.form.days_before_booking == null ||
                state.form.days_before_booking == ""
                ? true
                : value >= 1 && value <= 30
            }
          ),
          required: helpers.withMessage(validationMessages.required, required)
        },
        description: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        color: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        startTime: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        detentionLength: {
          minNumber: helpers.withMessage(
            validationMessages.minNumber,
            (value) => {
              return value >= 1
            }
          ),
          required: helpers.withMessage(validationMessages.required, required)
        },
        room: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        maxCapacity: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const isUpdateMode = computed(() => Boolean(props.editableDetention))

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const setFormData = (data) => {
      state.form = Object.assign(state.form, data)
    }

    const submit = (isUpdate) => {
      if (isFormValid.value) {
        const data = {
          name: state.form.name,
          days_before_booking: state.form.days_before_booking,
          description: state.form.description,
          default_start_time: moment(state.form.startTime, "hh:mm A").format(
            "HH:mm:ss"
          ),
          number_of_minutes: state.form.detentionLength,
          room_id: state.form.room.value.id,
          max_cap:
            state.form.maxCapacity === "-" ? null : state.form.maxCapacity,
          color: state.form.color,
          grad_years: state.form.gradeYears
        }
        if (isUpdate) {
          updateDetention({ ...data, id: props.editableDetention.id })
        } else {
          createDetention(data)
        }
      } else {
        v$.value.$touch()
      }
    }

    const createDetention = (data) => {
      state.isProcessing = true
      store
        .dispatch("detentions/createDetention", data)
        .then(() => {
          resetFormData()
          setResponseInfoBox("Success", "Successfully created!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
            store.dispatch("detentions/getDetentions")
            emit("cancel")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }
    const updateDetention = (data) => {
      state.isProcessing = true
      store
        .dispatch("detentions/updateDetentions", data)
        .then(() => {
          setResponseInfoBox("Success", "Successfully updated!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
            store.dispatch("detentions/getDetentions")
            emit("cancel")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const resetFormData = () => {
      setFormData({
        name: "",
        days_before_booking: null,
        description: "",
        color: "",
        startTime: "",
        detentionLength: "",
        room: null,
        maxCapacity: "-",
        gradeYears: null
      })
      v$.value.$reset()
    }

    const onCancel = () => {
      resetFormData()
      emit("cancel")
    }
    return {
      state,
      v$,
      submit,
      isFormValid,
      errors,
      isUpdateMode,
      onCancel,
      schoolGradYears
    }
  }
}
</script>
