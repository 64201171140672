<template>
  <div class="import-kiosk-passcode">
    <div
      class="import-background position-relative rounded-panel px-4 py-3 mt-4"
      @click="showModal"
    >
      <div
        class="d-flex justify-content-between align-items-center py-2 cursor-pointer"
      >
        <b
          class="text-value-lg fw-light d-block rounded-panel-title text-white"
        >
          Import Kiosk Passcode
          <HelpCenterButton
            width="16"
            height="16"
            classes="me-2"
            content-key="import_kiosk_passcode_user_menu"
            :is-old="true"
          />
        </b>
        <span
          class="d-flex align-items-center justify-content-center text-white rounded-circle p-2"
        >
          <i class="flaticon-upload text-value-lg"></i>
        </span>
      </div>
    </div>
    <!-- Import Modal -->
    <CModal
      backdrop="static"
      :visible="state.isModalVisible"
      v-if="state.manageImportMode"
      :close-on-backdrop="false"
      class="ps__child--consume"
      size="xl"
    >
      <div
        v-if="!state.welcomeScreen"
        class="d-flex justify-content-between align-items-center pt-4 px-5 pb-0 create-popup-header"
      >
        <button
          type="button"
          class="btn me-2 p-small bg-gradient-blue reverse"
          @click="cancelImport()"
        >
          <span class="btn bg-white px-4"
            ><b class="text-graident-blue reverse">{{
              state.uploadProgress == 100 ? "Close" : "Cancel Update"
            }}</b>
          </span>
        </button>

        <div v-show="selectedKioskPW && !selectedKioskPW.length" class="flex-1">
          <div
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            class="mt-4 mx-3 alert alert-danger no-icon text-center"
          >
            Please select records for the import.
          </div>
        </div>

        <div v-if="!state.reqResponse.message">
          <button
            :disabled="selectedKioskPW && !selectedKioskPW.length"
            class="btn bg-gradient-blue text-white"
            @click="finishImport()"
          >
            {{ "Import entries" }}
          </button>
        </div>
      </div>
      <div
        v-else
        class="pt-4 px-4 create-popup-header d-flex align-items-center justify-content-between"
      >
        <div
          class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
          @click=";(state.isModalVisible = false), resetImportData()"
        >
          <span class="close-icon"></span>
        </div>
      </div>
      <Loader :is-processing="state.isLoading" />
      <CContainer v-if="state.welcomeScreen" class="px-5 py-4">
        <CRow>
          <CCol md="12">
            <h2 class="text-center mb-4">
              Steps for importing kiosk passcodes via CSV upload
            </h2>
            <p>
              1. Download the
              <a href="/csv-samples/kiosksample.csv" download>sample file</a>
              and create your CSV. Do not modify the headers or the order of
              columns.
            </p>
            <p>2. Click “Browse” and locate the prepared file.</p>
            <p>3. Click “Submit”.</p>
            <p>
              It can take 48 hours for passcodes to be reflected in Pass. For
              details and further information, click on the blue “?” or look in
              your
              <a
                href="https://docs.google.com/document/d/e/2PACX-1vR-EC_d3lpr_eMXLOTM9ebzqIdPk2s47RM7dwTBlyU-Kqy0oTCip3sYU9dYvS9fOw/pub"
                target="_blank"
                >Admin Guide</a
              >.
            </p>

            <div>
              <div class="form-group">
                <input
                  :class="{
                    'is-invalid': state.reqResponse.errors['csv_file']
                  }"
                  readonly
                  type="text"
                  class="form-control w-auto pe-5"
                  :value="state.params.file ? state.params.file.name : ''"
                  @click="openFileIpnut('csvKioskPWs')"
                />
                <div
                  v-if="state.reqResponse.errors['csv_file']"
                  class="invalid-feedback"
                >
                  {{ state.reqResponse.errors["csv_file"][0] }}
                </div>
              </div>
              <CAlert
                v-if="state.reqResponse.message"
                class="flex-wrap"
                :color="state.reqResponse.type"
                :show="!!state.reqResponse.message"
              >
                {{ state.reqResponse.message }}
                <div v-if="state.reqResponse.errors" class="w-100">
                  <div
                    v-for="(err, index) in state.reqResponse.errors"
                    :key="index"
                  >
                    {{ err[0] }}
                  </div>
                </div>
              </CAlert>
              <div class="cs-file-input">
                <div class="upload-btn">
                  <i class="fi flaticon-upload me-1"></i> BROWSE
                </div>
                <input
                  id="csvKioskPWs"
                  type="file"
                  class="form-control"
                  @change="getFile"
                />
              </div>
              <div class="text-center mt-4 mb-2">
                <button
                  v-if="state.params.file"
                  class="btn bg-gradient-blue text-white px-5"
                  @click="importKioskPasscodes"
                >
                  Submit
                </button>
              </div>
            </div>
          </CCol>
        </CRow>
      </CContainer>

      <CContainer v-else class="px-5 pt-3 pb-5">
        <CRow v-if="state.manageImportMode">
          <CCol md="12">
            <div
              class="status-guides-container d-flex justify-content-between px-2 align-items-center mb-2 mt-4"
            >
              <div class="d-flex align-items-center">
                <input
                  :checked="!state.exclude.length"
                  type="checkbox"
                  class="me-2"
                  :disabled="!state.exclude.length"
                  @change="selectAllRecords()"
                />
                select all
              </div>
              <div class="per-page-container d-flex align-items-end">
                <div class="per-page-container">
                  <per-page-selector
                    :pagination="state.pagination"
                    :action="setPerPage"
                    :options="[
                      { label: '25', value: '25' },
                      { label: '50', value: '50' },
                      { label: '100', value: '100' }
                    ]"
                  />
                </div>
              </div>
            </div>
          </CCol>

          <CCol md="12" class="mt-0 overflow">
            <div v-if="state.kioskPasscodes" class="imported-users-table">
              <DataTable
                id="importKioskPasscodesTable"
                class="datatable import-users bg-white"
                :items="state.kioskPasscodes"
                :fields="state.dataTableFields"
                sorter
                hover
                column-filter
                :no-items-view="{
                  noResults: 'No items available',
                  noItems: 'No items available'
                }"
                @update:column-filter-value="onTableSearch"
                ref="tableTop"
              >
                >
                <template #select="{ item }">
                  <td>
                    <input
                      v-if="
                        (item.status && !item.passNotValidated) ||
                        (!item.status && item.passNotValidated)
                      "
                      :checked="!state.exclude.includes(item.id)"
                      type="checkbox"
                      class="me-2 visible-record"
                      :row="item.index"
                      @click="setMainToggle(item.id)"
                    />
                    <input v-else disabled type="checkbox" class="me-2" />
                  </td>
                </template>

                <template #kiosk_passcode="{ item }">
                  <td>
                    <input
                      v-model="item.kiosk_passcode"
                      :disabled="!item.status"
                      :readonly="state.editableUser.id !== item.id"
                      @input="validatePasscode($event.target.value, item)"
                    />
                  </td>
                </template>

                <template #status="{ item }">
                  <td>
                    <div v-if="item.passNotValidated" class="text-red">
                      Passcode must be 4-6 characters
                    </div>
                    <div
                      v-else
                      :class="item.status ? 'text-green' : 'text-red'"
                    >
                      {{
                        item.status ? "Record accepted" : "Email not recognized"
                      }}
                    </div>
                  </td>
                </template>

                <template #action="{ item }">
                  <td>
                    <div v-if="item.status">
                      <button
                        class="btn btn-edit me-2"
                        @click="
                          state.editableUser.id !== item.id
                            ? setEditable(item)
                            : setEditable(item, 'save')
                        "
                      >
                        <i
                          :class="`flaticon-${state.editableUser.id !== item.id ? 'edit' : 'diskette'} me-2`"
                        ></i>
                        {{
                          state.editableUser.id !== item.id ? "edit" : "save"
                        }}
                      </button>
                    </div>
                  </td>
                </template>
              </DataTable>

              <Pagination
                v-if="
                  state.pagination &&
                  state.pagination.pages &&
                  state.pagination.pages > 1
                "
                class="cs-pagination mt-4"
                :dots="false"
                :double-arrows="true"
                :active-page="state.pagination.activePage"
                :pages="state.pagination.pages"
                size="lg"
                align="center"
                @update:active-page="setActivePage"
              >
                <template #last-button>
                  <i class="flaticon-right-arrow-1"></i>
                </template>
                <template #first-button>
                  <i class="flaticon-left-arrow-2"></i>
                </template>
                <template #previous-button>
                  <i class="fi flaticon-left-arrow-1"></i>
                </template>
                <template #next-button>
                  <i class="fi flaticon-chevron"></i>
                </template>
              </Pagination>
            </div>
          </CCol>
        </CRow>
      </CContainer>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
    <CModal v-else :show="state.isModalVisible" size="xl">
      <template #header-wrapper>
        <div
          class="pt-4 px-4 create-popup-header d-flex align-items-center justify-content-between"
        >
          <div
            class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
            @click=";(state.isModalVisible = false), resetImportData()"
          >
            <span class="close-icon"></span>
          </div>
        </div>
      </template>
      <Loader :is-processing="state.isLoading" />
      <CContainer class="px-5 py-4">
        <CRow>
          <h4>
            Your file is being processed. Please allow 15 minutes for this
            process to complete.
          </h4>
        </CRow>
      </CContainer>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { reactive, computed, watch } from "vue"
import { useStore } from "vuex"
import PerPageSelector from "@/v3components/PerPageSelector.vue"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"
import Loader from "@/v3components/shared/Loader/Loader.vue"
import helpers from "@/helpers/index.js"

export default {
  name: "ImportKioskPW",
  components: {
    PerPageSelector,
    HelpCenterButton,
    Loader
  },
  setup() {
    const store = useStore()

    const state = reactive({
      exclude: [],
      welcomeScreen: true,
      uploadProgress: 0,
      manageImportMode: true,
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      },
      isLoading: false,
      isModalVisible: false,
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      kioskPasscodes: [],
      params: {
        fileName: "",
        file: null
      },
      editableUser: {},
      dataTableFields: [
        { key: "select", label: "", filter: false, sorter: false },
        { key: "email", label: "Email", _style: "max-width:110px" },
        {
          key: "kiosk_passcode",
          label: "Kiosk Passcode",
          _style: "max-width:110px"
        },
        { key: "status", label: "Status", filter: false },
        { key: "action", label: "Action", sorter: false, filter: false }
      ]
    })

    const isImportSuccessful = computed(
      () => store.getters["kiosks/isImportSuccessful"]
    )

    const PCsFileName = computed(() => store.getters["kiosks/PCsFileName"])

    const kioskImportPasscodes = computed(
      () => store.getters["kiosks/kioskImportPasscodes"]
    )

    const selectedKioskPW = computed(() => {
      return state.pagination.total <= state.exclude.length
    })

    watch(
      () => PCsFileName.value,
      (value) => {
        state.params.fileName = value.fileName
        getImportedPCs(value.fileName)
        state.isLoading = false
      }
    )

    watch(
      () => isImportSuccessful.value,
      (value) => {
        state.isLoading = false
        if (value) {
          setSuccessResponse("Successfully imported!")
        } else {
          setErrorResponse()
        }
      }
    )

    const getImportedPCs = (fileName, page) => {
      state.pagination.activePage = page ? page : 1
      const filterData = {
        page: state.pagination.activePage,
        per_page: state.pagination.per_page.value
      }
      const data = {
        fileName: fileName,
        filterData
      }
      store
        .dispatch("kiosks/getKioskImportedPasscodes", data)
        .then((response) => {
          const data = response.data
          if (data.data) {
            store.commit("kiosks/SET_KIOSK_PASSCODE", data.data)
          }
          if (data.meta) {
            state.pagination.total = data.meta.total
            state.pagination.from = data.meta.from
            state.pagination.to = data.meta.to
            state.pagination.pages = Math.ceil(
              data.meta.total / data.meta.per_page
            )
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
          state.kioskPasscodes = kioskImportPasscodes.value
          state.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    }

    const finishImport = () => {
      state.manageImportMode = false
      const excludedItems = {
        exclude: state.exclude
      }
      const data = {
        fileName: state.params.fileName,
        excludedItems
      }
      store
        .dispatch("kiosks/finalimportKioskPasscodes", data)
        .then(() => {
          state.isLoading = false
          store.commit("kiosks/SET_IS_IMPORT_SUCCESSFUL", true)
        })
        .catch((err) => {
          state.isLoading = false
          store.commit("kiosks/SET_IS_IMPORT_SUCCESSFUL", false)
          const response = err.response.data
          setErrorResponse(response.message, response.errors)
        })
    }

    const cancelImport = () => {
      if (
        state.uploadProgress == 100 ||
        window.confirm("Are you sure you want to cancel import?")
      ) {
        state.isModalVisible = false
        store.commit("kiosks/SET_IS_IMPORT_SUCCESSFUL", false)
        setTimeout(() => {
          resetImportData()
        }, 500)
      }
    }

    const getFile = (event) => {
      const files = event.target.files
      if (files && files.length) {
        state.params.file = files[0]
        event.target.value = ""
      }
    }

    const importKioskPasscodes = () => {
      if (state.params.file) {
        state.isLoading = true
        const data = new FormData()
        data.append("csv_file", state.params.file)
        store
          .dispatch("kiosks/importKioskPasscodes", data)
          .then((response) => {
            state.welcomeScreen = false
            const data = response.data
            if (data && data.file_name) {
              state.params.fileName = data.file_name
              const passcodes = data.data
              if (passcodes && passcodes.data) {
                state.kioskPasscodes = passcodes.data.map((pass, index) => {
                  pass.isSelected = !!pass.status
                  pass.index = index
                  return pass
                })
              }
            }
          })
          .catch((err) => {
            state.isLoading = false
            const response = err.response.data
            setErrorResponse(response.message, response.errors)
          })
      }
    }

    const setEditable = (item, action) => {
      state.editableUser = item
      if (action === "save") {
        state.isLoading = true
        const passcode = {
          kiosk_passcode: item.kiosk_passcode
        }
        const data = {
          id: item.id,
          passcode
        }
        store
          .dispatch("kiosks/postEditedPasscode", data)
          .then(() => {
            state.isLoading = false
            state.editableUser = {}
          })
          .catch((err) => {
            state.isLoading = false
            const response = err.response.data
            state.setErrorResponse(response.message, response.errors)
          })
      }
    }

    const selectAllRecords = () => {
      state.exclude = []
    }

    const setPerPage = (option) => {
      if (option) {
        state.pagination.per_page = option
        getImportedPCs(PCsFileName.value.fileName)
      }
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      getImportedPCs(PCsFileName.value.fileName, state.pagination.activePage)
      helpers.scrollToTop()
    }

    const setMainToggle = (id) => {
      const index = state.exclude.findIndex((el) => el === id)
      index >= 0 ? state.exclude.splice(index, 1) : state.exclude.push(id)
    }

    const resetImportData = () => {
      resetResponseMessages()
      state.welcomeScreen = true
      state.manageImportMode = true
      state.uploadProgress = 0
      state.select = true
      state.currentStep = 1
      state.pagination = {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { value: 25, label: "25" }
      }
      state.params = {
        fileName: "",
        file: null
      }
      state.kioskPasscodes = []
      state.editableUser = {}
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Someting went wrong!",
        errors: errors ? errors : {}
      }
    }

    const showModal = (event) => {
      if (event.target.classList && event.target.classList.value !== "") {
        state.isModalVisible = true
      }
    }

    const openFileIpnut = (inputId) => {
      if (inputId) {
        document.getElementById(inputId).click()
      }
    }

    const validatePasscode = (value, item) => {
      const pass = value.replace(/\s/g, "")
      item.kiosk_passcode = pass
      item.passNotValidated = !(pass && pass.length < 7 && pass.length > 3)
      item.isSelected = !item.passNotValidated
    }

    const onTableSearch = () => {
      setActivePage(1)
    }

    const onPagesChange = (val) => {
      state.pagination.pages = val
    }

    return {
      state,
      showModal,
      importKioskPasscodes,
      selectedKioskPW,
      getFile,
      resetImportData,
      finishImport,
      cancelImport,
      getImportedPCs,
      openFileIpnut,
      resetResponseMessages,
      setSuccessResponse,
      setErrorResponse,
      setEditable,
      selectAllRecords,
      setPerPage,
      setActivePage,
      setMainToggle,
      validatePasscode,
      onTableSearch,
      onPagesChange
    }
  }
}
</script>
