<template>
  <div>
    <div class="form-group">
      <CustomSelect
        v-model="state.form.selectedTardyBadge"
        :filterable="true"
        :close-on-select="true"
        :options="tardyBadges"
        type="textarea"
        placeholder="Select Type"
        :invalid-feedback="
          errors.selectedTardyBadge || state.serverErrors.selectedTardyBadge
        "
        @update:model-value="onChangeTardyBadge"
      ></CustomSelect>
    </div>

    <div class="form-group">
      <FileUpload
        :uploaded-file="state.form.attendanceFile"
        :download-example-file-path="state.downloadExamplePath"
        @update:model-value="onFileUpload"
        @remove-file="removeFile"
        :invalid-feedback="
          errors.attendanceFile || state.serverErrors.selectedSource
        "
      >
      </FileUpload>
    </div>

    <div class="form-group">
      <InfoBox
        v-if="state.serverRes"
        class="mt-4"
        :class="{ danger: Object.keys(state.serverErrors).length }"
        :title="state.serverRes.title"
        >{{ state.serverRes.message }}</InfoBox
      >

      <div class="text-center">
        <LoadingButton class="me-2 px-4" @click="onCancel" rounded
          >Cancel</LoadingButton
        >
        <LoadingButton
          :is-loading="state.isProcessing"
          @click="submit"
          class="mt-4 px-4"
          solid
          rounded
          >Submit</LoadingButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"
import { useStore } from "vuex"
import { reactive, computed, onMounted } from "vue"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import FileUpload from "@/v3components/shared/Form/FileUpload.vue"

export default {
  name: "CreateTardyCsvUploadForm",
  components: {
    CustomSelect,
    LoadingButton,
    InfoBox,
    FileUpload
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      isProcessing: false,
      serverErrors: {},
      serverRes: null,
      form: {
        selectedTardyBadge: null,
        selectedSource: null,
        attendanceFile: null
      }
    })
    onMounted(() => {
      //TODO default value for source, will be removed in phase2
      state.form.selectedSource = attendanceSources
        ? attendanceSources[1]
        : { id: "GRADE_BOOK", label: "Grade Book" }
    })

    const validationMessages = {
      required: "This field is required"
    }

    const tardyBadges = [
      { id: "LTS", label: "LTS" },
      { id: "LTC", label: "LTC" }
    ]
    const attendanceSources = [
      { id: "SIS", label: "SIS" },
      { id: "GRADE_BOOK", label: "Grade Book" }
    ]

    const validations = {
      form: {
        selectedTardyBadge: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        // selectedSource: {
        //   required: helpers.withMessage(validationMessages.required, required)
        // },
        attendanceFile: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      },
      downloadExamplePath: ""
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })
    const onChangeTardyBadge = () => {
      if (state.form.selectedTardyBadge.id === "LTS") {
        state.downloadExamplePath = "/csv-samples/lts-file-upload-sample.csv"
      }
      if (state.form.selectedTardyBadge.id === "LTC") {
        state.downloadExamplePath = "/csv-samples/ltc-file-upload-sample.csv"
      }
    }

    const setFormData = (data) => {
      state.form = Object.assign(state.form, data)
    }

    const resetFormData = () => {
      setFormData({
        selectedTardyBadge: null,
        //selectedSource: null,
        attendanceFile: null
      })
    }
    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onFileUpload = (event) => {
      const files = event.target.files
      if (files && files.length) {
        state.form.attendanceFile = files[0]
        event.target.value = ""
      }
    }
    const removeFile = () => {
      state.form.attendanceFile = null
    }

    const submit = () => {
      if (isFormValid.value) {
        state.isProcessing = true
        const data = {
          source: state.form.selectedSource.id,
          tardy_badge: state.form.selectedTardyBadge.id,
          file: state.form.attendanceFile
        }
        const formData = new FormData()

        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key]
            formData.append(key, element)
          }
        }
        store
          .dispatch("attendance/uploadFile", formData)
          .then(() => {
            setTimeout(() => {
              setResponseInfoBox("Success", "Successfully created!")
              state.isProcessing = false
              state.serverErrors = {}
              setTimeout(() => {
                setResponseInfoBox()
                emit("cancel")
                resetFormData()
              }, 2000)
            })
          })
          .catch((err) => {
            const res = err.response.data
            state.serverErrors = res.errors ? res.errors : {}
            setResponseInfoBox("Error", res.message)
            state.isProcessing = false
          })
      } else {
        v$.value.$touch()
      }
    }
    const onCancel = () => {
      resetFormData()
      emit("cancel")
    }

    return {
      state,
      v$,
      validationMessages,
      tardyBadges,
      attendanceSources,
      submit,
      onChangeTardyBadge,
      removeFile,
      onFileUpload,
      isFormValid,
      errors,
      onCancel
    }
  }
}
</script>

<style>
.margin-auto {
  margin-left: auto;
}
</style>
