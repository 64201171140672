<template>
  <div class="page-select">
    <div
      class="page-select-showing"
      v-if="showingEntries && pagination && pagination.total > 0"
    >
      <span class="d-inline-block me-4"
        >Showing
        {{ pagination.per_page.label == "All entries" ? 1 : pagination.from }}
        to {{ pagination.to }} of {{ pagination.total }} entries
      </span>
    </div>
    <div v-if="pagination">
      <CustomSelect
        :filterable="true"
        :close-on-select="true"
        :options="options"
        type="textarea"
        v-model="state.selectedOption"
        :searchable="false"
        @update:model-value="onPageChange"
      ></CustomSelect>
    </div>
  </div>
</template>

<script>
import { computed, reactive, watchEffect } from "vue"
import CustomSelect from "../Form/CustomSelect.vue"

export default {
  components: { CustomSelect },
  name: "Perpage",
  emits: ["onPageChange"],
  props: {
    useDynamicUpdate: {
      type: Boolean,
      default: false
    },
    useFirstOptionAsDefault: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Object
    },
    options: {
      type: Array,
      deep: true,
      default: function () {
        return [
          {
            label: "25",
            value: 25
          },
          {
            label: "50",
            value: 50
          },
          {
            label: "100",
            value: 100
          },
          {
            label: "All entries",
            value: 75
          }
        ]
      }
    },
    action: {
      type: Function
    },
    showingEntries: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const defaultValue = { label: "25 / page", value: 25 }
    const state = reactive({
      selectedOption: props.useFirstOptionAsDefault
        ? props?.options?.[0] || defaultValue
        : defaultValue
    })

    const pagination = computed(() => {
      return props.pagination
    })

    const onPageChange = (value) => {
      emit("onPageChange", value)
      state.selectedOption = value
    }

    watchEffect(() => {
      if (!props.useDynamicUpdate) return

      state.selectedOption = props.options.find(
        (option) => option.value === pagination.value.per_page.value
      )
    })

    return {
      state,
      onPageChange
    }
  }
}
</script>
